/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, Text, Button, Title, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--12 pt--16" name={"hlavicka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 pb--10 pt--10 flex--center" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"} style={{"position":"relative","backgroundColor":"var(--white)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/17432/60b7d21c738947df815b3d5605158b7b_e=3x1x1073x1079_s=660x_.png"} sizes={"100vw"} style={{"maxWidth":500}} srcSet={"https://cdn.swbpg.com/t/17432/60b7d21c738947df815b3d5605158b7b_e=3x1x1073x1079_s=350x_.png 350w, https://cdn.swbpg.com/t/17432/60b7d21c738947df815b3d5605158b7b_e=3x1x1073x1079_s=660x_.png 660w, https://cdn.swbpg.com/t/17432/60b7d21c738947df815b3d5605158b7b_e=3x1x1073x1079_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 pb--16 pl--12 pr--12 pt--16" anim={"4"} style={{"backgroundColor":"rgba(0,91,187,1)"}}>
              
              <Subtitle className="subtitle-box subtitle-box--left w--600" content={"<span style=\"color: var(--white);\">Jsme úklidová firma z Hradce Králové</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left ff--2 lh--2" style={{"maxWidth":560,"marginTop":32}} content={"<span style=\"color: var(--white);\"><fontsninja-text id=\"fontsninja-text-73\" class=\"fontsninja-family-55\" style=\"\">Všichni naši zaměstnanci\nbyli </fontsninja-text><fontsninja-text id=\"fontsninja-text-75\" class=\"fontsninja-family-59\" style=\"font-weight: bold;\">proškoleni</fontsninja-text><fontsninja-text id=\"fontsninja-text-73\" class=\"fontsninja-family-55\" style=\"\"><span style=\"font-weight: bold;\">&nbsp;</span>k práci se stroji a čisticími prostředky od firmy </fontsninja-text><span style=\"font-weight: bold;\"><fontsninja-text id=\"fontsninja-text-76\" class=\"fontsninja-family-59\">NILFISK a MERIDA,</fontsninja-text></span><fontsninja-text id=\"fontsninja-text-73\" class=\"fontsninja-family-55\" style=\"\"> které při práci používáme.<br>Naší předností je&nbsp;<span style=\"font-weight: bold;\">online systém pro rezervaci úklidu</span>, který vám usnadní celý proces objednání.<br>Věříme, že budete s našimi službami <span style=\"font-weight: bold;\">spokojeni</span> a budete se na nás <br><span style=\"font-weight: bold;\">s důvěrou obracet</span>.&nbsp;</fontsninja-text></span>"}>
              </Text>

              <Button className="btn-box fs--16" url={"https://uklidnaklik.inrs.cz/rs/"} href={"https://uklidnaklik.inrs.cz/rs/"} style={{"maxWidth":753,"backgroundColor":"var(--white)"}} content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">Rezervace úklidu</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pt--50" name={"sluzby"} style={{"paddingBottom":59}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" animS={"3"} style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--36" style={{"marginBottom":12}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">Typy úklidu</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3 flex--stretch" animS={"3"} style={{"maxWidth":1250}} columns={"3"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --center" anim={"2"} style={{"maxWidth":"","paddingTop":50,"paddingBottom":48,"backgroundColor":"var(--color-custom-1)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":8}} content={"<span style=\"font-weight: bold; color: var(--white);\">Jednorázový úklid</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--18" content={"<span style=\"color: var(--white);\">Vhodný pro všechny, kteří potřebují<br>uklidit&nbsp;ve standardním rozsahu.<br>Tzv. hloubkový úklid.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --center" anim={"2"} style={{"maxWidth":"","paddingTop":50,"paddingBottom":48,"backgroundColor":"var(--color-custom-1)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":8}} content={"<span style=\"font-weight: bold; color: var(--white);\">Pravidelný úklid</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Při pravidelném úklidu se stanete<br>našimi V.I.P. klienty a my vám můžeme<br>zaručit výhodnější ceny<br>než při jednorázovém úklidu.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --center" anim={"2"} style={{"maxWidth":"","paddingTop":50,"paddingBottom":48,"backgroundColor":"var(--color-custom-1)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":8}} content={"<span style=\"font-weight: bold; color: var(--white);\">Generální úklid</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Generální úklid je speciální v celkové důkladnosti a intenzitě úklidu. Často je objednáván tam, kde by standardní úklid nepokryl všechny úkony.<br></span>Např. po malování."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --center" anim={"2"} style={{"maxWidth":"","paddingTop":50,"paddingBottom":48,"backgroundColor":"var(--color-custom-1)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":8}} content={"<span style=\"font-weight: bold; color: var(--white);\">Parní čištění</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Provádíme parní čištění sedacích souprav, křesel, koberců a čalouněného nábytku. Čistíme párou s následným suchým vysáváním nebo také kombinací páry, vysávání a čisticího roztoku.<br>Velkou předností parního čištění je oživení látky a celková nižší zbytková vlhkost.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --center" anim={"2"} style={{"maxWidth":"","paddingTop":50,"paddingBottom":48,"backgroundColor":"var(--color-custom-1)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":8}} content={"<span style=\"font-weight: bold; color: var(--white);\">Parní čištění podlah, dlažeb<br>a obkladů</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Pára má skvělou vlastnost, dokáže pronikat<br>i do mikroskopických prohlubní.<br>Parní čištění kuchyňské linky, sporáku, digestoře, mikrovlnné trouby či lednice by ocenila každá hospodyňka.<br>Horký proud páry má výborný účinek<br>na zamaštěné části, kde se mastnota shromažďuje a špatně se čistí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --center" anim={"2"} style={{"maxWidth":"","paddingTop":50,"paddingBottom":48,"backgroundColor":"var(--color-custom-1)"}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"marginTop":8}} content={"<span style=\"font-weight: bold; color: var(--white);\">Sezónní úklid</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Jarní nebo předvánoční úklid na míru<br>podle vašich představ. <br>Mytí oken, zazimování věcí/oblečení,<br>žehlení,&nbsp;vše na individuální domluvě.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"reference"} style={{"paddingTop":40,"paddingBottom":59,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--36" style={{"marginBottom":9}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">Kde můžete naše služby využít?</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim6 --anim-s4 --center el--3" anim={"6"} animS={"4"} style={{"marginTop":20}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/38e19239ee9e46118b29ddab38901d08_s=860x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/38e19239ee9e46118b29ddab38901d08_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/38e19239ee9e46118b29ddab38901d08_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/38e19239ee9e46118b29ddab38901d08_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/38e19239ee9e46118b29ddab38901d08_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17432/38e19239ee9e46118b29ddab38901d08_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box pb--0 pl--0 pr--0 pt--0" style={{"marginTop":10}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">ÚKLID DOMÁCNOSTÍ</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/2647c21cf6ee43758911fb95946295e5_s=860x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/2647c21cf6ee43758911fb95946295e5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/2647c21cf6ee43758911fb95946295e5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/2647c21cf6ee43758911fb95946295e5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/2647c21cf6ee43758911fb95946295e5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17432/2647c21cf6ee43758911fb95946295e5_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box pb--0 pl--0 pr--0 pt--0" style={{"marginTop":10}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">ÚKLID KANCELÁŘÍ</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/b78f9a066ea7480388d7e8a819f693b8_s=860x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/b78f9a066ea7480388d7e8a819f693b8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/b78f9a066ea7480388d7e8a819f693b8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/b78f9a066ea7480388d7e8a819f693b8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/b78f9a066ea7480388d7e8a819f693b8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17432/b78f9a066ea7480388d7e8a819f693b8_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box pb--0 pl--0 pr--0 pt--0" style={{"marginTop":10}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">ÚKLID HOTELŮ A RESTAURACÍ</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim6 --anim-s4 --center el--3" anim={"6"} animS={"4"} style={{"marginTop":20}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/c704c996a7184642bb52c8520a20abce_s=860x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/c704c996a7184642bb52c8520a20abce_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/c704c996a7184642bb52c8520a20abce_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/c704c996a7184642bb52c8520a20abce_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/c704c996a7184642bb52c8520a20abce_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17432/c704c996a7184642bb52c8520a20abce_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box pb--0 pl--0 pr--0 pt--0" style={{"marginTop":10}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">ÚKLID BUSINESS OBJEKTŮ</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/013c92120c0b48e89ca99d32dd65e5c9_s=860x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/013c92120c0b48e89ca99d32dd65e5c9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/013c92120c0b48e89ca99d32dd65e5c9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/013c92120c0b48e89ca99d32dd65e5c9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/013c92120c0b48e89ca99d32dd65e5c9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17432/013c92120c0b48e89ca99d32dd65e5c9_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box pb--0 pl--0 pr--0 pt--0" style={{"marginTop":10}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">ÚKLID SPOLEČENSKÝCH PROSTOR</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/75822093f40e4f50a822ea5c6eaa46db_s=860x_.jpg"} sizes={"100vw"} style={{"border":"3px solid white","maxWidth":"","boxSizing":"border-box"}} srcSet={"https://cdn.swbpg.com/t/17432/75822093f40e4f50a822ea5c6eaa46db_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/75822093f40e4f50a822ea5c6eaa46db_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/75822093f40e4f50a822ea5c6eaa46db_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/75822093f40e4f50a822ea5c6eaa46db_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17432/75822093f40e4f50a822ea5c6eaa46db_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box pb--0 pl--0 pr--0 pt--0" style={{"marginTop":10}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">ČIŠTĚNÍ ČALOUNĚNÍ</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --center el--1" anim={"2"} columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--center" name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Divider className="mt--20">
              </Divider>

              <Title className="title-box title-box--center fs--36" content={"<span style=\"font-weight: bold;\"><br>Kontakt:</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--20" content={"<span style=\"color: var(--color-supplementary);\"><span style=\"font-weight: bold;\">+420</span> <span style=\"font-weight: bold;\">734 148 215</span></span><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":0}} content={"<a href=\"mailto:uklidnaklik@gmail.com?subject=Popt%C3%A1vka+z+webu\" style=\"font-weight: bold; color: var(--color-supplementary);\">uklidnaklik@gmail.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}